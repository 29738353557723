"use client";
import React, { useEffect, useState } from "react";
import android from "../../images/Android.svg";
import androidWhite from "../../images/android_white.svg";
import appStore from "../../images/App_Store.png";
import { useTheme } from "../../providers";

interface CountdownTimerProps {
    launchDate: string; // Launch date in ISO format (e.g., '2024-07-31T00:00:00Z')
    labels: string[];
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
    launchDate,
    labels,
}) => {
    const { theme } = useTheme();

    const calculateTimeLeft = () => {
        const difference = +new Date(launchDate) - +new Date();
        const [days, hours, minutes, seconds] = labels;
        let timeLeft = {
            [days]: 0,
            [hours]: 0,
            [minutes]: 0,
            [seconds]: 0,
        };

        if (difference > 0) {
            timeLeft = {
                [days]: Math.floor(difference / (1000 * 60 * 60 * 24)),
                [hours]: Math.floor((difference / (1000 * 60 * 60)) % 24),
                [minutes]: Math.floor((difference / 1000 / 60) % 60),
                [seconds]: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft, calculateTimeLeft]);

    const timerComponents: JSX.Element[] = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval as keyof typeof timeLeft]) {
            return;
        }

        function convertDate(dateString: any) {
            const date = new Date(dateString);

            const day = String(date.getUTCDate()).padStart(2, "0");
            const month = String(date.getUTCMonth() + 1).padStart(2, "0");
            const year = date.getUTCFullYear();

            const formattedDate = `${day}-${month}-${year}`;

            return formattedDate;
        }

        const timerComponents: JSX.Element[] = [];
        timerComponents.push(
            <div
                key={interval}
                className="mx-2 flex flex-col items-center sm:mx-4"
            >
                <div className="flex h-16 w-16 items-center justify-center rounded-full bg-primary sm:h-20 sm:w-20 md:h-24 md:w-24">
                    <span className="text-xl font-bold text-white sm:text-2xl md:text-4xl">
                        {timeLeft[interval as keyof typeof timeLeft]}
                    </span>
                </div>
                <span className="mt-2 text-base font-medium text-body-color">
                    {interval}
                </span>
            </div>
        );
    });

    return (
        <div className="flex items-center justify-center text-center">
            <div className="">
                <h3 className="mb-6 text-xl font-bold text-primary sm:text-2xl md:text-3xl">
                    🚀 31-07-2024 🚀
                </h3>
                {timerComponents.length ? (
                    <div className="flex flex-wrap justify-center">
                        {timerComponents}
                    </div>
                ) : (
                    <span className="text-xl sm:text-2xl md:text-3xl text-primary">
                        We are live!
                    </span>
                )}
                <div className="mt-10 flex items-center justify-center">
                    <a
                        href="https://apps.apple.com/in/app/linguist/id6566188647"
                        target="_blank"
                    >
                        <img
                            src={appStore}
                            alt="Get it on Google Play"
                            className="w-60"
                        />
                    </a>
                    <a
                        href="https://linguest-assets-dev.s3.ap-south-1.amazonaws.com/LinguistEdu-v-0.0.7.apk"
                        target="_blank"
                    >
                        {theme === "dark" ? (
                            <>
                                <img
                                    src={androidWhite}
                                    typeof="image/svg+xml"
                                    alt="Get it on Google Play"
                                    className="w-60"
                                />
                            </>
                        ) : (
                            <>
                                <img
                                    src={android}
                                    typeof="image/svg+xml"
                                    alt="Get it on Google Play"
                                    className="w-60"
                                />
                            </>
                        )}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default CountdownTimer;
